<template>
  <div class="content_body" v-loading="loading">

    <el-form label-width="80px">
      <el-form-item label="Schema">
        <el-input v-model="statement" type="textarea" :rows="16"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="executeSchema">提交</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>
<script>
import Schema from "@/api/System/Schema/schema";
export default {
  data() {
    return {
      loading: false,
      statement: "",
    };
  },
  methods: {
    executeSchema() {
      var that = this;
      that.loading = true;
      Schema.executeSchema({ "Statement": that.statement })
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "执行成功！！！",
              duration: 5000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 5000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>